import * as myConst from "../main/constants";

function NoEncontrado() {
	return (
        <main className="main-content  mt-0">
            <section className="my-10">
            <div className="container">
                <div className="row">
                <div className="col-lg-6 my-auto">
                    <h1 className="display-1 text-bolder text-gradient text-danger">Error 404</h1>
                    <h2>No encontrado</h2>
                    <p className="lead">Mejor ve al inicio para encontrar lo que buscas</p>
                    <button type="button" className="btn bg-gradient-dark mt-4">Inicio</button>
                </div>
                <div className="col-lg-6 my-auto position-relative">
                    <img className="w-100 position-relative" src={myConst.roots.pathImg+"/illustrations/error-404.png"} alt="404-error" />
                </div>
                </div>
            </div>
            </section>
        </main>
    );
}
export default NoEncontrado;
