import jwtDecode from 'jwt-decode'
import { Buffer } from 'buffer';
window.Buffer = Buffer

const tool = {
    outUser: function() {
        // SETTING LOCALS FOR USER
        localStorage.removeItem("token");
        localStorage.removeItem("nav");
        localStorage.removeItem("usuarioid");
        localStorage.removeItem("usuarioindex");
        localStorage.removeItem("usuarionick");
        localStorage.removeItem("usuariounion");
        localStorage.removeItem("usuarionombre");
        localStorage.removeItem("usuariorollid");
        localStorage.removeItem("usuariorollnombre");
        localStorage.removeItem("usuariorolldescripcion");
        localStorage.removeItem("empresaid");
        localStorage.removeItem("depto");
        localStorage.removeItem("mupio");
        localStorage.removeItem("picprofile");
        localStorage.removeItem("telefono");
        localStorage.removeItem("empresanombre");
        localStorage.removeItem("campanawhatsapp");
        localStorage.removeItem("theme");
        localStorage.clear();
    },

    setUser: function(arreglo){
        // SETTING LOCALS FOR USER
        if(typeof arreglo.token !== "undefined" && arreglo.token!=="" && arreglo.token !==null){
            localStorage.setItem(
                "token",
                JSON.stringify(arreglo.token)
            );
        }

        if(typeof arreglo.privilegees !== "undefined" && arreglo.privilegees!=="" && arreglo.privilegees !==null){
            window.localStorage.setItem("nav", JSON.stringify(arreglo.privilegees));
        }

        if(typeof arreglo.usuarioId !== "undefined" && arreglo.usuarioId!=="" && arreglo.usuarioId !==null){
            localStorage.setItem(
                "usuarioid",
                JSON.stringify(arreglo.usuarioId)
            );
        }

        if(typeof arreglo.usuarioIndex !== "undefined" && arreglo.usuarioIndex!=="" && arreglo.usuarioIndex !==null){
            localStorage.setItem(
                "usuarioindex",
                JSON.stringify(arreglo.usuarioIndex)
            );
        }

        if(typeof arreglo.usuarioNick !== "undefined" && arreglo.usuarioNick!=="" && arreglo.usuarioNick !==null){
            localStorage.setItem(
                "usuarionick", JSON.stringify(arreglo.usuarioNick)
            );
        }
        if(typeof arreglo.usuarioUnion !== "undefined" && arreglo.usuarioUnion!=="" && arreglo.usuarioUnion !==null){
            localStorage.setItem(
                "usuariounion",
                JSON.stringify(arreglo.usuarioUnion)
            );
        }
        if(typeof arreglo.usuarioNombre !== "undefined" && arreglo.usuarioRolId!=="" && arreglo.usuarioRolId !==null){
            localStorage.setItem(
                "usuarionombre", JSON.stringify(arreglo.usuarioNombre)
            );
        }
        
        if(typeof arreglo.usuarioRolId !== "undefined" && arreglo.usuarioRolId!=="" && arreglo.usuarioRolId !==null){        
            localStorage.setItem(
                "usuariorollid",
                JSON.stringify(arreglo.usuarioRolId)
            );
        }
        if(typeof arreglo.usuarioRolNombre !== "undefined" && arreglo.usuarioRolNombre!=="" && arreglo.usuarioRolNombre !==null){        
            localStorage.setItem(
                "usuariorollnombre",
                JSON.stringify(arreglo.usuarioRolNombre)
            );
        }
        if(typeof arreglo.usuarioRolDescripcion !== "undefined" && arreglo.usuarioRolDescripcion!=="" && arreglo.usuarioRolDescripcion !==null){
            localStorage.setItem(
                "usuariorolldescripcion",
                JSON.stringify(arreglo.usuarioRolDescripcion)
            );
        }

        if(typeof arreglo.usuarioEmpresaId !== "undefined" && arreglo.usuarioEmpresaId!=="" && arreglo.usuarioEmpresaId !==null){
            localStorage.setItem(
                "empresaid",
                JSON.stringify(arreglo.usuarioEmpresaId)
            );
        }

        if(typeof arreglo.usuarioDepto !== "undefined" && arreglo.usuarioDepto!=="" && arreglo.usuarioDepto !==null){
            localStorage.setItem(
                "depto",
                JSON.stringify(arreglo.usuarioDepto)
            );
        }


        if(typeof arreglo.usuarioMupio !== "undefined" && arreglo.usuarioMupio!=="" && arreglo.usuarioMupio !==null){
            localStorage.setItem(
                "mupio",
                JSON.stringify(arreglo.usuarioMupio)
            );
        }

        if(typeof arreglo.picProfile !== "undefined" && arreglo.picProfile!=="" && arreglo.picProfile !==null){
            localStorage.setItem(
                "picprofile",
                JSON.stringify(arreglo.picProfile)
            );
        }

        if(typeof arreglo.telefono !== "undefined" && arreglo.telefono!=="" && arreglo.telefono !==null){
            localStorage.setItem(
                "telefono",
                JSON.stringify(arreglo.telefono)
            );
        }

        if(typeof arreglo.usuarioEmpresaNombre !== "undefined" && arreglo.usuarioEmpresaNombre!=="" && arreglo.usuarioEmpresaNombre !==null){
            localStorage.setItem(
                "empresanombre",
                JSON.stringify(arreglo.usuarioEmpresaNombre)
            );
        }

        if(typeof arreglo.usuarioEmpresaWP !== "undefined" && arreglo.usuarioEmpresaWP!=="" && arreglo.usuarioEmpresaWP !==null){
            localStorage.setItem(
                "campanawhatsapp",
                JSON.stringify(arreglo.usuarioEmpresaWP)
            );
        }

        if(typeof arreglo.theme !== "undefined" && arreglo.theme!=="" && arreglo.theme !==null){
            localStorage.setItem(
                "theme",
                JSON.stringify(arreglo.theme)
            );
        }
    },

    getUser: function(){
        // GIVE USER DATA
        // eslint-disable-next-line no-unneeded-ternary
        const estaLogeado = (JSON.parse(localStorage.getItem("usuarionick")) !== null )? true : false;
        return {
            isLogged: estaLogeado,
            token: JSON.parse(localStorage.getItem("token")),
            usuarioId: JSON.parse(localStorage.getItem("usuarioid")),
            usuarioIndex: JSON.parse(localStorage.getItem("usuarioindex")),
            usuarioNick: JSON.parse(localStorage.getItem("usuarionick")),
            usuarioUnion: JSON.parse(localStorage.getItem("usuariounion")),
            usuarioNombre: JSON.parse(localStorage.getItem("usuarionombre")),
            usuarioRollNombre: JSON.parse(localStorage.getItem("usuariorollnombre")),
            usuarioRollDescripcion: JSON.parse(localStorage.getItem("usuariorolldescripcion")),
            usuarioRollId: JSON.parse(localStorage.getItem("usuariorollid")),
            usuarioEmpresaId: JSON.parse(localStorage.getItem("empresaid")),
            usuarioDepto: JSON.parse(localStorage.getItem("depto")),
            usuarioMupio: JSON.parse(localStorage.getItem("mupio")),
            picProfile: JSON.parse(localStorage.getItem("picprofile")),
            telefono: JSON.parse(localStorage.getItem("telefono")),
            usuarioEmpresaNombre: JSON.parse(localStorage.getItem("empresanombre")),
            usuarioempresaWP: JSON.parse(localStorage.getItem("campanawhatsapp")),
            theme: JSON.parse(localStorage.getItem("theme"))
        }
    },

    parseJwt: function(token){
        if(token !== null){
            const decode = JSON.parse(atob(token.split('.')[1]));
            // console.log('Time TOKENIZED: ', process.env.REACT_APP_JWT_PRIVATE_KEY);
            if (decode.exp * 1000 < new Date().getTime()) {
                tool.outUser()
                // console.log('Time Expired')
                return null
            }
        }   
        return token
        
    },

    decodeJWT: function(token){
        return jwtDecode(token)
    },

    /**
     * encriptar RECEIVE A STRING TO MASK
     * @param {*} text 
     * @returns 
     */
    encriptar: (text) => {
        let encoded = ""
        if (text !== "undefined" && text !== null && text !== "") {
            encoded = Buffer.from(text.toString(), 'utf-8').toString('base64').toString('base64')
        }
        return encoded
    },

    /**
     * decriptar RECEIVE A MASK LARGE STRING TO UN-MASK-IT
     * @param {*} text 
     * @returns 
     */
    decriptar: (text) => {
        let plain = ""
        if (text !== "undefined" && text !== null && text !== "") {
            plain = Buffer.from(Buffer.from(text.toString(), 'base64'), 'base64').toString('utf-8')
        }
        return plain
    },

    verifyUser: function(){
        // GET USER PROPERTIES
        const preuser = tool.getUser()
        if(preuser.token !== null){
            // TOKEN VALIDITY
            const pretoken = tool.parseJwt(preuser.token)
            // COMPARE FOR RETURNS
            if(pretoken !== preuser.token){
                return null
            }else{
                return preuser
            }
        }else{
            return null
        }
    },

    /**
     * getNav: return object for navigator or null to -> index
     * @param {*} elUsuario: localStorage with user definition set in login
     * @returns {menu:{options}}
     */
    getNav: function(){
        // TOKEN DECODE
        const elToken = window.localStorage.getItem("nav")
        // console.log('El token para el menu: ', elToken)
        if(elToken !== null && elToken !== "undefined"){
            const decodedtoken = tool.decodeJWT(elToken)
            // CHECK PRIVILEGES FOR RETURNS
            return (decodedtoken.sub.length > 0)? decodedtoken.sub : null ;
        }
    },

    /**
     * setRecord: write a record to localStorage for change route and reuse it
     * @param {*} theScript: path to script
     * @returns none
     */
    setRecord: function(elRecord){
        if(elRecord !== ""){
            localStorage.setItem("record", JSON.stringify(elRecord))
        }
    },

    /**
     * removeStorage: blanco es y gallina lo pone
     * @param {*} theScript: path to script
     * @returns none
     */
     removeStorage: function(elStorage){
        if(elStorage !== ""){
            localStorage.removeItem(elStorage)
        }
    },

    /**
     * getRecord: return a localStorage record or null
     *
     * @returns none
     */
     getRecord: function(){
        let record = null;
        if(localStorage.getItem("record") !== null){   
            record = JSON.parse(localStorage.getItem("record"))
        }
        return record
    },

    /**
     * appendScript: add script to document body
     * @param {*} theScript: path to script
     * @returns none
     */
    appendScript: function(theScript){
        const script = document.createElement("script");
        script.src = theScript;
        script.async = true;
        document.head.appendChild(script);        
    },

	/**
	 * validEmail RETURN RANDOM STRING AS LONG AS LENGHT
	 * @param {*} length (INTEGER)
	 * @returns 'aS.u$F'
	 */
	validEmail: function(email){
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	},
	/**
	 * statsColor RETURN ['textcolor','backgroundcolor'] by valor in semaphore colors
	 * @param {*} valor (float)
	 * @returns {text:'',background:''}
	 */
    statsColor: function(type,valor){
        switch(type){
            case 'hex':
                if(valor < 0.5){return {text:'#F1F1F1', background:'#F25961'}} 
                if(valor >= 0.5 && valor < 0.85){return {text:'#F1F1F1', background:'#FF9E27'}} 
                if(valor >= 0.85 && valor <= 1){return {text:'#F1F1F1', background:'#2BB930'}}
                if(valor > 1 ){return {text:'#F1F1F1', background:'#48ABf7'}}
            break;
            case 'light':
                if(valor < 0.5){return {text:'white', background:'red'}} 
                if(valor >= 0.5 && valor < 0.85){return {text:'black', background:'orange'}} 
                if(valor >= 0.85 && valor <= 1){return {text:'white', background:'green'}}
                if(valor > 1 ){return {text:'white', background:'blue'}}
            break;
            default:
                if(valor < 0.5){return {text:'white', background:'danger'}} 
                if(valor >= 0.5 && valor < 0.85){return {text:'dark', background:'warning'}} 
                if(valor >= 0.85 && valor <= 1){return {text:'white', background:'success'}}
                if(valor > 1 ){return {text:'white', background:'info'}}
            break; 
        }
    },

    /**
     * scrollId: scroll h bar to #element
     * @param {element} id of element to scroll 
     */
    scrollId: function(element){
        const section = document.querySelector( '#'+element);
        section.scrollIntoView( { behavior: 'smooth', block: 'center' } );
    },


    /**
     * getBrowserName: return navigator info
     * 
     */
    getBrowserName: function(userAgent){
        if (userAgent.includes("Firefox")) {
            // "Mozilla/5.0 (X11; Linux i686; rv:104.0) Gecko/20100101 Firefox/104.0"
            return "Mozilla Firefox";
        } else if (userAgent.includes("SamsungBrowser")) {
            // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36"
            return "Samsung Internet";
        } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
            // "Mozilla/5.0 (Macintosh; Intel Mac OS X 12_5_1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36 OPR/90.0.4480.54"
            return "Opera";
        } else if (userAgent.includes("Edge")) {
            // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
            return "Microsoft Edge (Legacy)";
        } else if (userAgent.includes("Edg")) {
            // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36 Edg/104.0.1293.70"
            return "Microsoft Edge (Chromium)";
        } else if (userAgent.includes("Chrome")) {
            // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36"
            return "Google Chrome or Chromium";
        } else if (userAgent.includes("Safari")) {
            // "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6 Mobile/15E148 Safari/604.1"
            return "Apple Safari";
        } else {
            return "unknown";
        }
    },

    deviceInfo: function(){
        const module = {
            options: [],
            header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera],
            dataos: [
                { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
                { name: 'Windows', value: 'Win', version: 'NT' },
                { name: 'iPhone', value: 'iPhone', version: 'OS' },
                { name: 'iPad', value: 'iPad', version: 'OS' },
                { name: 'Kindle', value: 'Silk', version: 'Silk' },
                { name: 'Android', value: 'Android', version: 'Android' },
                { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
                { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
                { name: 'Macintosh', value: 'Mac', version: 'OS X' },
                { name: 'Linux', value: 'Linux', version: 'rv' },
                { name: 'Palm', value: 'Palm', version: 'PalmOS' }
            ],
            databrowser: [
                { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
                { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
                { name: 'Safari', value: 'Safari', version: 'Version' },
                { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
                { name: 'Opera', value: 'Opera', version: 'Opera' },
                { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
                { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
            ],
            init: function () {
                const agent = this.header.join(' ')
                const os = this.matchItem(agent, this.dataos)
                const browser = this.matchItem(agent, this.databrowser)                
                return { os, browser };
            },
            matchItem: function (string, data) {
                let i = 0
                let j = 0
                const html = ''
                let regex
                let regexv
                let match
                let matches
                let version
                
                for (i = 0; i < data.length; i += 1) {
                    regex = new RegExp(data[i].value, 'i');
                    match = regex.test(string);
                    if (match) {
                        regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
                        matches = string.match(regexv);
                        version = '';
                        if (matches) { if (matches[1]) { matches = matches[1]; } }
                        if (matches) {
                            matches = matches.split(/[._]+/);
                            for (j = 0; j < matches.length; j += 1) {
                                if (j === 0) {
                                    version += matches[j] + '.';
                                } else {
                                    version += matches[j];
                                }
                            }
                        } else {
                            version = '0';
                        }
                        return {
                            name: data[i].name,
                            version: parseFloat(version)
                        };
                    }
                }
                return { name: 'unknown', version: 0 };
            }
        };
        
        const e = module.init()

        return ({
            os:{
                name:e.os.name,
                version:e.os.version
            },
            browser:{
                name:e.browser.name,
                version:e.browser.version
            },
            navigator:{
                userAgent:navigator.userAgent,
                appVersion:navigator.appVersion,
                platform:navigator.platform,
                vendor:navigator.vendor
            }
        })      
    },

    /**
     * copyText: copy a text to clipboard returning true or false
     *
     * @returns none
     */
    copyText: function(textToCopy){
        if(textToCopy){
            navigator.clipboard.writeText(textToCopy);
            return true;
        }else{
            return false;
        }
    },

    /**
     * showMessage: put an alert box with {icon,title,text,color} in head
     * @returns none
     */
    showMessage: function(props){
        const lasPropiedades = {
            color: props.color || 'light',
            icon: props.icon || 'light',
            title: props.title || 'Atención',
            text: props.text || 'light'
        };

        return (`<div className="alert alert-${lasPropiedades.color} alert-dismissible fade show" role="alert">
                    <span className="alert-icon"><i className="${lasPropiedades.icon}"></i></span>
                    <span className="alert-text"><strong> ${lasPropiedades.title} </strong> ${lasPropiedades.text} </span>
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>`);
    },

/*     geoLocation: function(){
        const options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        };
          
        const position = (pos) => {
            const crd = pos.coords;

            return ({
                lat: `${crd.latitude}`,
                long: `${crd.longitude}`,
                accuracy: `${crd.accuracy}`
            })
        }
          
        const error = (err) => {
            return ({
                code: `${err.code}`,
                error: `${err.message}`
            })
        }
    
        navigator.geolocation.getCurrentPosition(position, error, options)
    }, */
}

export default tool;