import { createContext, useState } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [waiting, setWaiting] = useState(false)
    const [elUsuario, setElUsuario] = useState({
        isLogged: false,
        token: null,
        usuarioId: null,
        usuarioIndex: null,
        usuarioNick: null,
        usuarioUnion: null,
        usuarioNombre: null,
        usuarioCampanaId: null,
        usuarioCampanaPlan: null,
        usuarioCampanaNombre: null,
        socketId: null,
        usuarioRollNombre: null,
        usuarioRollDescripcion: null,
        usuarioRollId: null,
        usuarioDepto: null,
        usuarioMupio: null,
        picProfile: null,
        telefono: null,
        usuarioCampanaWP: null,
        theme: null,
        systemAlert:{visible:false,content:''}
    });
    return (
        <UserContext.Provider value={{ waiting, setWaiting, elUsuario, setElUsuario}}>
            {children}
        </UserContext.Provider>
    );
}