import { Link } from "react-router-dom"

export const Foot = () => {
    const year = new Date().getFullYear();
    
	return (
        <footer className="footer pt-3  ">
            <div className="container-fluid">
                <div className="row align-items-center justify-content-lg-between">
                    <div className="col-lg-6 mb-lg-0 mb-4">
                        <div className="copyright text-center text-sm text-muted text-lg-start">
                            © {year},
                            desarrollado <i className="fa fa-gears"></i> por 
                            <Link to="https://www.dinamicaempresarial.com.co" className="font-weight-bold" target="_blank"> Dinámica Empresarial </Link>
                            <br/>
                            Aprendizaje, innovacion y resultados
                        </div>
                    </div>
                    <div className="col-lg-6">
                    <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                        <li className="nav-item">
                        <Link to="https://www.dinamicaempresarial.com.co" className="nav-link text-muted" target="_blank">Dinamica Empresarial</Link>
                        </li>
                        <li className="nav-item">
                        <Link to="https://www.dinamicaempresarial.com.co" className="nav-link text-muted" target="_blank">Nosotros</Link>
                        </li>
                        <li className="nav-item">
                        <Link to="https://www.dinamicaempresarial.com.co" className="nav-link text-muted" target="_blank">Blog</Link>
                        </li>
                        <li className="nav-item">
                        <Link to="https://www.dinamicaempresarial.com.co" className="nav-link pe-0 text-muted" target="_blank">Crear una cuenta</Link>
                        </li>
                    </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};
