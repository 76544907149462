import { lazy, Suspense } from 'react'
import { BrowserRouter, Route} from "react-router-dom";
import { publicRoutes, privateRoutes } from "../../services/routes";

import { NotFound } from "../../pages/404";
import { Forbidden } from "../../pages/forbidden";
import Waiting from "../../pages/parts/waiting";

// import { versions } from '../../main/constants';
import tool from '../../services/tools';

// lazy(() => import(/* webpackChunkName: "login" */ "../../pages/login"));
const Login = lazy(() => import(/* webpackChunkName: "login" */ "../../pages/default"));
const Index = lazy(() => import(/* webpackChunkName: "login" */ "../../pages/login"));
const About = lazy(() => import(/* webpackChunkName: "login" */ "../../pages/about"));
const Contact= lazy(() => import(/* webpackChunkName: "login" */ "../../pages/contact"));
const NewAccount = lazy(() => import(/* webpackChunkName: "login" */  "../../pages/newaccount"));

const DashboardAdmin = lazy(() => import(/* webpackChunkName: "dashboard" */ "../../pages/dashboardadmin"));
const Dashboard = lazy(() => import(/* webpackChunkName: "dashboard" */ "../../pages/dashboard"));
const DashboardEnterprise = lazy(() => import(/* webpackChunkName: "dashboard" */ "../../pages/dashboardenterprise"));

const DashboardDemo = lazy(() => import(/* webpackChunkName: "dashboard" */ "../../pages/dashboarddemo"));
// import Dashboardadmin from "../../pages/admin";
const DocumentosAdd = lazy(() => import(/* webpackChunkName: "dashboard" */ "../../pages/documentos/documentosAdd"));
const DocumentosList = lazy(() => import(/* webpackChunkName: "dashboard" */ "../../pages/documentos/documentosList"));


/* import CampanaListado from "../../pages/campana/campanaList";
import CampanaAdd from "../../pages/campana/campanaAdd";
import VotanteListado from "../../pages/votantes/votantesList";
import VotanteAdd from "../../pages/votantes/votantesAdd";
import UsuarioListado from "../../pages/usuarios/usuariosList";
import UsuarioAdd from "../../pages/usuarios/usuariosAdd";
import PublicidadListado from "../../pages/publicidad/publicidadList";
import PublicidadAdd from "../../pages/publicidad/publicidadAdd";
import PublicidadView from "../../pages/publicidad/publicidadView";
import StatsPunto from "../../pages/stats/statspunto"
import StatsMupio from "../../pages/stats/statsmupio"
import StatsMeta from "../../pages/stats/statsmetas"
import StatsGenero from "../../pages/stats/statsgenero"
import StatsEdad from "../../pages/stats/statsedad" */


export const MisRutas = () => {
    const userNow = tool.getUser()

    const callUsers = (component) =>{
        if(!userNow.isLogged){
            return <Forbidden />
        }
        return component
    };

    return (
        <Suspense fallback={<Waiting />}>
            <BrowserRouter>
                <NotFound>
                    <Route path="/" element={<Index />} />
                    <Route exact path={publicRoutes.INDEX} element={<Index />} />
                    <Route exact path={publicRoutes.LOGIN} element={<Login />} />
                    <Route exact path={publicRoutes.CONTACT} element={<Contact />} />
                    <Route exact path={publicRoutes.ABOUT} element={<About />} />
                    <Route exact path={publicRoutes.REGISTER} element={<NewAccount />} />

                    <Route exact path={privateRoutes.DASHBOARD_ADMON} element={(callUsers)? <DashboardAdmin isLogged={callUsers} /> : <Forbidden /> } />
                    <Route exact path={privateRoutes.DASHBOARD} element={(callUsers)? <Dashboard isLogged={callUsers} /> : <Forbidden /> } />
                    <Route exact path={privateRoutes.DASHBOARD_DEMO} element={(callUsers)? <DashboardDemo isLogged={callUsers} /> : <Forbidden /> } />
                    <Route exact path={privateRoutes.DASHBOARD_ENTERPRISE} element={(callUsers)? <DashboardEnterprise isLogged={callUsers} /> : <Forbidden /> } /> 

                    <Route exact path={privateRoutes.documentosLISTADO} >
                        <Route index element={callUsers? <DocumentosList isLogged={callUsers} /> : <Forbidden /> } />
                        <Route path=":mode" element={callUsers? <DocumentosList isLogged={callUsers} /> : <Forbidden /> } />
                        <Route path=":spec" element={callUsers? <DocumentosList isLogged={callUsers} /> : <Forbidden /> } />                    
                    </Route>
                    <Route path={privateRoutes.documentosADD} >
                        <Route index element={callUsers? <DocumentosAdd isLogged={callUsers} /> : <Forbidden /> } />
                        <Route path=":reference" element={callUsers? <DocumentosAdd isLogged={callUsers} /> : <Forbidden /> } />
                        <Route path=":idvotante" element={callUsers? <DocumentosAdd isLogged={callUsers} /> : <Forbidden /> } />
                    </Route>



                </NotFound>
            </BrowserRouter>
        </Suspense>
    )
}
