// PATH ROUTES SPLIT FOR AUTH AND VISITORS
export const publicRoutes = {
    INDEX:"/",
    LOGIN:"/login",
    NOTFOUND:"/404",
    CONTACT:"/contact",
    ABOUT:"/about",
    REGISTER:"/newacount",
    RESET_PASSWORD:"/resetpass",
    PUBLICIDAD_VIEW:"/verpublicidad", // VER PUBLICIDAD
}

// ROUTES FOR AUTH USERS 
export const privateRoutes = {
    CONF: "/conf", // ESTABLECER LA CONFIGURACION DE MI APLICACION: CUANDO GENERAR LOS REPORTES Y ENVIARLOS AL CORREO
    NOTFOUND:"/404",
    DASHBOARD:"/dashboard",
    DASHBOARD_DEMO:"/demo",
    DASHBOARD_ADMON:"/dashboardqa",
    DASHBOARD_ENTERPRISE:"/enterprise",
    LIDERES_INSCRIBIR:"/lideresinscripcion", // INSCRIBIR LIDERES PARA QUE CUMPLAN LAS METAS
    VOTANTE_RESUMEN:"/votanteresumen", // VER COMO VA LA COLECCION DE VOTANTES
    documentosADD:"/documentosadd", // REGISTRO DE DOCUMENTOS
    documentosLISTADO:"/documentoslist", // LISTADO DE DOCUMENTOS
    documentosAPROBAR:"/documentoscheck", // APROBACION DE DOCUMENTOS
    documentosVIEW:"/documentosview", // VISUALIZACION DE DOCUMENTOS

    USUARIO_ADD:"/usuarioinscripcion", // PLANTILLA DE INSCRIPCION DE RECLUTADORES
    USUARIO_LISTADO:"/usuariolistado", // LISTADO DE RECLUTADORES
    PUBLICIDAD_ADD:"/publicidadregistro", // PLANTILLA DE REGISTRO DE PUBLICIDAD
    PUBLICIDAD_LISTADO:"/mipublicidad", // LISTADO DE PUBLICIDADES
    CAMPANA_METAS:"/campanametas", // ESTABLECER LAS METAS A CADA LIDER O RECLUTADOR 
    CAMPANA_LISTADO:"/campanalist", // VER EL LISTADO DE MIS CAPANAS
    CAMPANA_ADD:"/campanaadd", // AGREGAR EDITAR MIS CAPANAS
    PERFORMANCE_PUNTO:"/statspunto", // VER EL RENDIMIENTO DE LOS VOTANTES EN CADA PUNTO DE VOTACION
    PERFORMANCE_METAS:"/statsmetas", // VER EL CUMPLIMIENTO DE METAS POR LIDER EN MI CAMPANA
    PERFORMANCE_MUPIO:"/statsmunicipio", // VER EL RENDIMIENTO DE LOS VOTANTES EN CADA MUNICIPIO
    PERFORMANCE_EDAD:"/statsedad", // LA DISTRIBUCION DE VOTANTES POR EDAD
    PERFORMANCE_GENERO:"/statsgenero", // LA DISTRIBUCION DE VOTANTES POR GENERO
    CAMPANA_MENSAJES_PERFORMANCE:"/campana/messages/performance", // VER EL RENDIMIENTO DE LOS MENSAJES CREADOS EN POLIMETRIKA
    CAMPANA_MENSAJES:"/campana/messages",// ENVIAR MENSAJES O FLYERS A LOS WHATSAPP DE CADA 
}